import Head from "next/head";
import { useRouter } from "next/router";
import { capitalize } from "pages/enroll";
import { useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import { format } from "date-fns";

const standard = {
  title: "Cosmetic College",
  description:
    "We are one of the leading training academies in the UK, offering the highest quality beauty and aesthetics training ensuring that our students become successful and competent practitioners.",
};

const productsSchema = (rest) => {
  const items = rest.courses.items.map((x) => {
    return {
      ...x,
      event: rest.events.find((y) => y.contentful_id === x.sys.id),
    };
  });
  const schemaItems = items
    .map((x) => {
      const image =
        x.fields.listingImage &&
        x.fields.listingImage.fields &&
        x.fields.listingImage.fields.file
          ? `https:${x.fields.listingImage.fields.file.url}`
          : null;

      if (!x.event) {
        return null;
      }

      const product = {
        "@context": "https://schema.org",
        "@type": "Product",
        productID: x.event.id,
        name: x.fields.title,
        description: x.fields.metadata.fields.description,
        url: `https://cosmetic.college${x.fields.url}`,
        image: image,
        brand: "Cosmetic College",
        offers: x.event.events.map((z) => {
          return {
            "@type": "Offer",
            price: `${(z.price || x.standardPrice) / 100}`,
            priceCurrency: "GBP",
            itemCondition: "https://schema.org/NewCondition",
            availability: "https://schema.org/InStock",
          };
        }),
      };

      return product;
    })
    .filter(Boolean);
  return schemaItems;
};

const productSchema = (rest) => {
  const image = rest.entry.fields.listingImage
    ? `https:${rest.entry.fields.listingImage.fields.file.url}`
    : null;

  const product = {
    "@context": "https://schema.org",
    "@type": "Product",
    productID: rest.event.id,
    name: rest.entry.fields.title,
    description: rest.entry.fields.metadata.fields.description,
    url: `https://cosmetic.college${rest.entry.fields.url}`,
    image: image,
    brand: "Cosmetic College",
    offers:
      rest.event.events &&
      rest.event.events.map((x) => {
        return {
          "@type": "Offer",
          price: `${x.price / 100}`,
          priceCurrency: "GBP",
          itemCondition: "https://schema.org/NewCondition",
          availability: "https://schema.org/InStock",
        };
      }),
  };
  return product;
};

const course = (rest) => {
  return {
    hasCourseInstance: rest.event.events.map((x) => {
      return {
        "@type": "CourseInstance",
        courseMode: ["part-time", "Online"],
        "location": "Cosmetic College, 3 Locks Court, 429 Crofton Road, Locksbottom, Orpington, Kent, BR6 8NL",
        startDate: format(new Date(x.start), 'yyyy-MM-dd'),
        endDate: x.settings && x.settings.dates && x.settings.dates[x.settings.dates.length - 1] 
          ? format(new Date(x.settings.dates[x.settings.dates.length - 1].end), 'yyyy-MM-dd')
          : format(new Date(x.end), 'yyyy-MM-dd'),
        offers: {
          "@type": "Offer",
          price: `${x.seat_price / 100}`,
          priceCurrency: "GBP",
        },
      };
    }),
  };
};

export const PageHead = ({ metadata, ...rest }) => {
  const router = useRouter();
  const [schema] = useState({
    product: rest && rest.event ? productSchema(rest) : null,
    products:
      rest && rest.events && rest.courses && rest.courses.items
        ? productsSchema(rest)
        : null,
    course:
      rest && rest.event
        ? {
            "@context": "https://schema.org/",
            "@type": "Course",
            courseCode: rest.event.id,
            name: rest.entry.fields.title,
            description: rest.entry.fields.metadata.fields.description,
            provider: {
              "@type": "CollegeOrUniversity",
              name: "Cosmetic College",
              url: { "@id": "https://cosmetic.college/" },
            },
            ...course(rest),
          }
        : null,
  });

  useEffect(() => {
    try {
      if (schema.product) {
        // @ts-ignore
        if (window && window.fbq) {
          // @ts-ignore
          window.fbq("track", "ViewContent", {
            value:
              schema.product.offers && schema.product.offers[0]
                ? schema.product.offers[0].price
                : 1,
            currency: "GBP",
            content_name: schema.product.name,
            content_type: "product",
            content_ids: schema.product.productID,
          });
        }
        if (window && window.gtag) {
          gtag("event", "view_item", {
            currency: "GBP",
            value:
              schema.product.offers && schema.product.offers[0]
                ? schema.product.offers[0].price
                : 0.1,
            items: [
              {
                item_id: schema.product.productID,
                item_name: schema.product.name,
                affiliation: "Cosmetic College",
                currency: "GBP",
                item_brand: "Cosmetic College",
                item_category: "Training Courses",
                location_id: "ChIJgQNjw-er2EcRQXRgnS11j3s",
                price:
                  schema.product.offers && schema.product.offers[0]
                    ? schema.product.offers[0].price
                    : 0.1,
                quantity: 1,
              },
            ],
          });
        }
      }
      if (schema.products) {
        if (window && window.gtag) {
          gtag("event", "view_item_list", {
            item_list_id: rest.entry.fields.categories
              ? rest.entry.fields.categories[0]
              : rest.entry.fields.title,
            item_list_name: rest.entry.fields.categories
              ? capitalize(rest.entry.fields.categories[0])
              : rest.entry.fields.title,
            items: schema.products.map((product) => [
              {
                item_id: product.productID,
                item_name: product.name,
                affiliation: "Cosmetic College",
                currency: "GBP",
                item_brand: "Cosmetic College",
                item_category: "Training Courses",
                location_id: "ChIJgQNjw-er2EcRQXRgnS11j3s",
                price:
                  product.offers && product.offers[0]
                    ? product.offers[0].price
                    : 0.1,
                quantity: 1,
              },
            ]),
          });
        }
      }
      if (window.gtag) {
        setTimeout(() => {
          window.gtag("config", "AW-418666221/pTD1CPGF9PUBEO2t0ccB", {
            phone_conversion_number: "0333 015 5117",
          });
        }, 500);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, []);

  if (!metadata || !metadata.fields) {
    return (
      <Head>
        <title>{standard.title}</title>
        <meta name="description" content={standard.description} />
        <link
          rel="canonical"
          href={`https://www.cosmetic.college${router.asPath}`}
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`https://www.cosmetic.college${router.asPath}`}
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://www.cosmetic.college${router.asPath}`}
        />
      </Head>
    );
  }

  const {
    fields: { title, description, image },
  } = metadata;

  return (
    <Head>
      <title>{title || standard.title}</title>
      <meta name="description" content={description || standard.description} />
      {image && (
        <>
          <meta content={`https:${image.fields.file.url}`} name="og:image" />
        </>
      )}
      <link
        rel="canonical"
        href={`https://www.cosmetic.college${router.asPath}`}
      />
      <link
        rel="alternate"
        hrefLang="en"
        href={`https://www.cosmetic.college${router.asPath}`}
      />
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://www.cosmetic.college${router.asPath}`}
      />
      {schema.course ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema.course),
          }}
        ></script>
      ) : null}
      {schema.product ? (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(schema.product),
          }}
        ></script>
      ) : null}
      {schema.products && schema.products.length > 0
        ? schema.products.map((x) => {
            return (
              <script
                type="application/ld+json"
                key={`product-schema-${x.productID}`}
                dangerouslySetInnerHTML={{
                  __html: JSON.stringify(x),
                }}
              ></script>
            );
          })
        : null}
    </Head>
  );
};
