import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richContentOptions } from "components";

export default function SEO(props) {
  return (
    <div className="invisible h-0">
      {documentToReactComponents(
        {
          // @ts-ignore
          nodeType: "document",
          data: {},
          content: props.fields.content.content,
        },
        richContentOptions
      )}
    </div>
  );
}
