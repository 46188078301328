import dynamic from "next/dynamic";
import Link from "next/link";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import SEO from "./seo";

const Embed = dynamic(() => import("components/embeds"), { ssr: false });
const Intro = dynamic(() => import("components/intro"));
const Hero = dynamic(() => import("components/hero"));
const Testimonial = dynamic(() => import("components/testimonial"));
const Content = dynamic(() => import("components/content"));
const Grid = dynamic(() => import("components/grid"));
const Panel = dynamic(() => import("components/panel"));
const Card = dynamic(() => import("components/card"));
const Image = dynamic(() => import("components/image"));
const Earnings = dynamic(() => import("components/earnings"));
const Pricing = dynamic(() => import("components/pricing"));
const Horizontal = dynamic(() => import("components/panels/horizontal"));
const Split = dynamic(() => import("components/panels/split"));
const FullCentered = dynamic(() => import("components/panels/centered"));
const Justified = dynamic(() => import("components/panels/justified"));
const Stacked = dynamic(() => import("components/panels/stacked"));
const Accordion = dynamic(() => import("components/accordion"));
const OnlineBooking = dynamic(() => import("components/online-booking"));
const PTItem = dynamic(() => import("components/practitioner-item"));
const Banner = dynamic(() => import("components/banner"));
const newCourse = dynamic(() => import("components/content-types/new-course"));

export const ComponentMap = {
  intro: Intro,
  article: Content,
  testimonial: Testimonial,
  contentReferenceGrid: Grid,
  panel: Panel,
  card: Card,
  horizontalPanel: Horizontal,
  splitPanel: Split,
  hero: Hero,
  pricing: Pricing,
  earnings: Earnings,
  centered: FullCentered,
  justified: Justified,
  stacked: Stacked,
  accordion: Accordion,
  onlineCourseBooking: OnlineBooking,
  practitionerDayItem: PTItem,
  banner: Banner,
  seoBlock: SEO,
  newCourseTypeModule: newCourse,
};

const Bold = (text) => <strong className="font-normal">{text}</strong>;

const L = (link) => {
  return (
    <Link
      href={link.data.uri}
      className="font-medium text-brand-600 hover:text-brand-500"
    >
      {link.content.map((x) => x.value).join(" ")}
    </Link>
  );
};

const A = (node, next) => {
  const { fields } = node.data.target;
  return fields.url ? (
    <Link href={fields.url ?? ""} className="cursor-pointer">
      <figure>
        <Image
          id="JKHVFJ"
          alt=""
          className="w-full rounded-lg"
          fields={node.data.target}
          width={fields.file.details.image.width}
          height={fields.file.details.image.height}
        />
        {fields.title && <figcaption>{fields.title}</figcaption>}
      </figure>
    </Link>
  ) : (
    <figure>
      <Image
        id="JKHVFJ"
        alt=""
        className="w-full rounded-lg"
        fields={node.data.target}
        width={fields.file.details.image.width}
        height={fields.file.details.image.height}
      />
      {fields.title && <figcaption>{fields.title}</figcaption>}
    </figure>
  );
  // return (
  //   <Link href={fields.url ?? ""} className="cursor-pointer">
  //     <figure>
  //       <Image
  //         id="JKHVFJ"
  //         alt=""
  //         className="w-full rounded-lg"
  //         fields={node.data.target}
  //         width={fields.file.details.image.width}
  //         height={fields.file.details.image.height}
  //       />
  //       {fields.title && <figcaption>{fields.title}</figcaption>}
  //     </figure>
  //   </Link>
  // );
};

const E = (node, next) => {
  const { sys, fields } = node.data.target;
  if (ComponentMap[sys.contentType.sys.id]) {
    const Cmp = ComponentMap[sys.contentType.sys.id];
    return (
      <Cmp
        key={`${node.nodeType}-${sys.contentType.sys.id}`}
        fields={fields}
        sys={sys}
        includes={{}}
      />
    );
  }
  return null;
};

const LI = (props) => {
  const UnTaggedChildren = documentToReactComponents(props, {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => children,
      [BLOCKS.LIST_ITEM]: (node, children) => children,
    },
  });
  return <li>{UnTaggedChildren}</li>;
};

const Iframe = (link) => {
  return (
    <span className="block aspect-w-16 aspect-h-9 mt-16 rounded-lg overflow-hidden">
      <iframe
        className="w-full"
        width="100%"
        height="100%"
        src={link.data.uri}
        title="YouTube video player"
        frameBorder="0"
        loading="lazy"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </span>
  );
};

const C = (node) => {
  const [type, url] = node.split("|");
  return <Embed type={type} url={url} />;
};

export const richContentOptions = {
  renderMark: {
    [MARKS.BOLD]: Bold,
    [MARKS.CODE]: C,
  },
  renderNode: {
    [INLINES.HYPERLINK]: (link) => {
      return link.data.uri.includes("youtube") ? (
        <Iframe {...link} />
      ) : (
        <L {...link} />
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: A,
    [INLINES.EMBEDDED_ENTRY]: E,
    [BLOCKS.LIST_ITEM]: LI,
  },
};
